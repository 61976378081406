<template>
  <v-container class="pt-7 pb-11">
    <h1 class="text-h4 mb-5">{{ 'usersRelevancy'|localize }}</h1>
    <div class="white elevation-2 rounded">
      <v-row class="ma-0 mb-2">
        <v-col cols="4">
          <v-text-field
            :background-color="search ? 'serenade' : ''"
            v-model.trim="search"
            prepend-inner-icon="mdi-magnify"
            outlined
            hide-details
            dense
            clearable
            :placeholder="'search'|localize"
          />
        </v-col>
        <v-spacer/>
        <v-col cols="auto" class="d-flex">
          <v-btn v-if="showFilters" class="mr-2" text color="primary" @click="resetFilters();getRelevancy()"><v-icon left>mdi-sync</v-icon> {{ 'resetFilters'|localize }}</v-btn>
        </v-col>
        <v-col cols="auto" class="d-flex">
          <v-btn v-model="showFilters" @click="showFilters = !showFilters" text :color="(showFilters) ? 'primary' : 'grey lighten-1'">
            <v-icon v-if="showFilters" left>mdi-eye</v-icon>
            <v-icon v-else left>mdi-eye-off</v-icon>
            {{ 'toggleFilters'|localize }}
          </v-btn>
        </v-col>
      </v-row>
      <v-container class="py-0" v-if="showFilters">
        <v-row class="my-0 mb-2">
          <v-col cols="3" class="py-0">
            <v-select
              :background-color="filters.company.length ? 'serenade' : ''"
              v-model="filters.company"
              :items="companies"
              :disabled="filtersLoading"
              dense
              hide-details
              outlined
              clearable
              multiple
              :placeholder="'company'|localize"
              @change="clearRelevancy();getRelevancy();"
            >
              <template v-slot:item="{ item, on }">
                <v-list-item-title>{{ item }}</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="text-truncate" style="max-width: 100px">{{ item }}</span>
                <span v-if="index === 1" class="grey--text text-caption">+{{ filters.company.length - 1 }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="2" class="py-0">
            <v-select
              :background-color="filters.transport_mode.length ? 'serenade' : ''"
              v-model="filters.transport_mode"
              :items="transportModeList"
              :disabled="filtersLoading"
              item-text="name"
              item-value="value"
              dense
              hide-details
              outlined
              clearable
              multiple
              :placeholder="'transportMode'|localize"
              @change="clearRelevancy();getRelevancy();"
            >
              <template v-slot:item="{ item, on }">
                <v-icon class="mr-2" color="silver-chalice">{{ TRANSPORT_ICONS[item['value']] }}</v-icon>
                <v-list-item-title>{{ item.value|localize }}</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-icon
                  v-if="index <= 1"
                  class="mr-1"
                  color="silver-chalice"
                >{{ TRANSPORT_ICONS[item['value']] }}
                </v-icon>
                <span v-if="index === 2" class="grey--text text-caption">+{{ filters.transport_mode.length - 2 }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="2" class="py-0">
            <v-autocomplete
              :background-color="filters.country_from.length ? 'serenade' : ''"
              v-model="filters.country_from"
              :items="countriesFrom"
              :disabled="filtersLoading"
              :item-text="item => `${item.name} ${item.code}`"
              :item-value="item => `${item.code}`"
              dense
              hide-details
              outlined
              :placeholder="'from'|localize"
              clearable
              multiple
              @change="clearRelevancy();getRelevancy();"
            >
              <template v-slot:item="{ item, on }">
                <v-list-item-title>{{ item.name }} ({{item.code}})</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="text-truncate mr-1" style="max-width: 100px">{{ item.name }}</span>
                <span v-if="index === 1" class="grey--text text-caption mr-1">+{{ filters.country_from.length - 1 }}</span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="2" class="py-0">
            <v-autocomplete
              :background-color="filters.country_to.length ? 'serenade' : ''"
              v-model="filters.country_to"
              :items="countriesTo"
              :disabled="filtersLoading"
              :item-text="item => `${item.name} ${item.code}`"
              :item-value="item => `${item.code}`"
              dense
              hide-details
              outlined
              :placeholder="'to'|localize"
              clearable
              multiple
              @change="clearRelevancy();getRelevancy();"
            >
              <template v-slot:item="{ item, on }">
                <v-list-item-title>{{ item.name }} ({{item.code}})</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="text-truncate mr-1" style="max-width: 100px">{{ item.name }}</span>
                <span v-if="index === 1" class="grey--text text-caption mr-1">+{{ filters.country_to.length - 1 }}</span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="3" class="py-0">
            <v-select
              :background-color="filters.delivery_terms.length ? 'serenade' : ''"
              v-model="filters.delivery_terms"
              :items="deliveryTerms"
              item-text="text"
              item-value="value"
              dense
              hide-details
              outlined
              :placeholder="'deliveryTerms'|localize"
              clearable
              multiple
              @change="clearRelevancy();getRelevancy();"
            >
              <template v-slot:item="{ item, on }">
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="text-truncate mr-1" style="max-width: 100px">{{ item.value }}</span>
                <span v-if="index === 1" class="grey--text text-caption mr-1">+{{ filters.delivery_terms.length - 1 }}</span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row class="my-0 mb-2">
          <v-col cols="3" class="py-0">
            <v-select
              :background-color="filters.type_of_goods.length ? 'serenade' : ''"
              v-model="filters.type_of_goods"
              :items="goodTypesList"
              :disabled="filtersLoading"
              item-text="value"
              item-value="key"
              dense
              hide-details
              outlined
              clearable
              multiple
              :placeholder="'typeOfGoods'|localize"
              @change="clearRelevancy();getRelevancy();"
            >
              <template v-slot:item="{ item, on }">
                <v-list-item-title>{{ item.value }}</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="text-truncate" style="max-width: 50px">{{ item.value }}</span>
                <span v-if="index === 1" class="grey--text text-caption ml-1">+{{ filters.type_of_goods.length - 1 }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="3" class="py-0">
            <v-select
              :background-color="filters.special_requirements.length ? 'serenade' : ''"
              v-model="filters.special_requirements"
              :items="requirementsList"
              :disabled="filtersLoading"
              item-text="value"
              item-value="key"
              dense
              hide-details
              outlined
              :placeholder="'specialRequirements'|localize"
              clearable
              multiple
              @change="clearRelevancy();getRelevancy();"
            >
              <template v-slot:item="{ item, on }">
                <v-icon class="mr-2" :color="SPECIAL_REQUIREMENTS_COLORS[item['key']]">{{ SPECIAL_REQUIREMENTS_ICONS[item['key']] }}</v-icon>
                <v-list-item-title>{{ item['key']|localize }}</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-icon class="mr-2" :color="SPECIAL_REQUIREMENTS_COLORS[item['key']]">{{ SPECIAL_REQUIREMENTS_ICONS[item['key']] }}</v-icon>
              </template>
            </v-select>
          </v-col>
          <v-col cols="3" class="py-0">
            <v-select
              :background-color="filters.receiver.length ? 'serenade' : ''"
              v-model="filters.receiver"
              :items="receiverList"
              :disabled="filtersLoading"
              item-text="value"
              item-value="key"
              dense
              hide-details
              outlined
              :placeholder="'receiver'|localize"
              clearable
              multiple
              @change="clearRelevancy();getRelevancy();"
            >
              <template v-slot:item="{ item, on }">
                <v-list-item-title>{{ item.value }}</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <div>
                  <span v-if="index > 0">, </span>
                  {{ item.value }}
                </div>
              </template>
            </v-select>
          </v-col>
          <v-col cols="3" class="py-0">
            <v-select
              :background-color="filters.relevanciesSet ? 'serenade' : ''"
              v-model="filters.relevanciesSet"
              :items="relevanciesSetList"
              :disabled="filtersLoading"
              item-text="value"
              item-value="key"
              dense
              hide-details
              outlined
              clearable
              :placeholder="'relevanciesSet'|localize"
              @change="clearRelevancy();getRelevancy();"
            >
              <template v-slot:item="{ item, on }">
                <v-list-item-title>{{ item.value }}</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <div>
                  <span v-if="index > 0">, </span>
                  {{ item.value }}
                </div>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-container>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="computedRelevancyList"
        :loading="loading"
        :single-select="false"
        :sort-by="defaultSortBy"
        :sort-desc="defaultSortDesc"
        :options.sync="options"
        :server-items-length="totalItems"
        :no-data-text="'noDataAvailable'|localize"
        loader-height="2"
        hide-default-footer
        show-select
        checkbox-color="primary"
        item-key="local_id"
      >
        <template v-slot:item.user_name="{ item }">
          <router-link class="black--text" :to="{name: 'create-user', query: {company_id: item.company_id, user_id: item.user_id}, hash: '#relevancy'}">
            {{ item['user_name'] }}
          </router-link>
          <i class="name-col text-caption">{{ item['company_name'] }}</i>
          <i class="name-col grey--text text-caption">{{ item['user_email'] }}</i>
        </template>
        <template v-slot:item.transport_mode="{ item }">
          <div class="d-flex align-center">
            <v-tooltip top color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="silver-chalice">{{ TRANSPORT_ICONS[item['transport_mode']] }}</v-icon>
              </template>
              <span>{{ item['transport_mode']|localize }}</span>
            </v-tooltip>
            <template v-if="item['black_list']">
              <v-tooltip right color="black">
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-inline-flex align-center ml-2" v-bind="attrs" v-on="on">
                    <v-icon color="error">mdi-account-cancel</v-icon>
                    <span class="error--text text-caption"> {{ item['black_list'].length }}</span>
                  </div>
                </template>
                <template>
                  <div class="py-1">
                    <h5 class="text-subtitle-2 mb-1">{{ 'blockedClients'|localize }}</h5>
                    <ul class="px-2">
                      <li v-for="(client, index) in item['black_list']" :key="index">{{ client['name'] }} <i class="text-caption">(ID: {{client['id']}})</i></li>
                    </ul>
                  </div>
                </template>
              </v-tooltip>
            </template>
          </div>
        </template>
        <template v-slot:item.type_of_goods="{ item }">
          <div class="py-2">
            <v-chip x-small label v-for="type in item['type_of_goods']" :key="type" class="mr-1 px-1">{{ goodTypesList.find(x => x.key === type).value }}</v-chip>
          </div>
        </template>
        <template v-slot:item.delivery_terms="{ item }">
          <div class="py-2">
            <v-chip x-small label v-for="value in item['delivery_terms']" :key="value" class="mr-1 px-1">{{ value }}</v-chip>
          </div>
        </template>
        <template v-slot:item.country_from="{ item }">
          <span v-for="(country, index) in item['country_from']" :key="country.code">
            <template v-if="index < 2"><span v-if="index > 0">, </span>{{ country.name }}</template>
            <span v-if="index === 2" class="grey--text text-caption ml-1">+{{ item['country_from'].length - 2 }}</span>
          </span>
        </template>
        <template v-slot:item.country_to="{ item }">
          <span v-for="(country, index) in item['country_to']" :key="country.code">
            <template v-if="index < 2"><span v-if="index > 0">, </span>{{ country.name }}</template>
            <span v-if="index === 2" class="grey--text text-caption ml-1">+{{ item['country_to'].length - 2 }}</span>
          </span>
        </template>
        <template v-slot:item.special_requirements="{ item }">
          <div class="py-2">
            <v-chip x-small label v-for="requirement in item['special_requirements']" :key="requirement" class="mr-1 px-1">{{ requirement|localize }}</v-chip>
          </div>
        </template>
        <template v-slot:item.receiver="{ item }">
          <div class="py-2">
            <v-chip x-small label v-for="receiverItem in item['receiver']" :key="receiverItem" class="mr-1 px-1">{{ receiverItem|localize }}</v-chip>
          </div>
        </template>
        <template v-slot:item.settings="{ item }">
          <v-progress-circular
            v-if="loginLocalId === item.local_id"
            indeterminate
            :width="1"
            :size="20"
          />
          <v-menu v-else left transition="slide-y-transition" :disabled="!!loginLocalId">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar v-bind="attrs" v-on="on" size="45" tile>
                <v-icon size="20">mdi-dots-vertical</v-icon>
              </v-avatar>
            </template>
            <v-list>
              <v-list-item link @click="loginAsUser(item)">
                <v-list-item-title>{{ 'loginAs'|localize }}</v-list-item-title>
              </v-list-item>
              <v-list-item :disabled="!item.relevancy_id" link :to="{name: 'user-relevancy', query: {company_id: item.company_id, user_id: item.user_id, relevancy_id: item.relevancy_id}}">
                <v-list-item-title>{{ 'edit'|localize }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.is_valid="{ item }">
          <v-tooltip v-if="!item.is_valid" top color="black" max-width="320">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" color="error" v-on="on" size="20">mdi-flag</v-icon>
            </template>
            <span>{{'relevancyCategoriesMissed'|localize}}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-container class="py-6">
        <v-row>
          <v-col cols="4" offset="4">
            <v-pagination
              color="primary"
              v-model="currentPage"
              :length="totalPages"
              :total-visible="7"
              :disabled="loading"
              @input="relevancyList = [];getRelevancy();"
            />
          </v-col>
          <v-col cols="4">
            <div class="d-flex align-center justify-end">
              <span class="caption mr-4">{{'usersPerPage'|localize}}</span>
              <v-select
                style="max-width: 100px"
                v-model="itemsPerPage"
                :items="itemsPerPageList"
                dense
                hide-details
                outlined
                :disabled="saveLoading"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-divider />
      <v-container class="px-4">
        <v-row class="my-0">
          <v-col cols="4" >
            <h2 v-if="showRelevancySettings" class="text-h6 mb-5">{{ 'relevancySettings'|localize }}</h2>
          </v-col>
          <v-spacer/>
          <v-col cols="auto" class="d-flex justify-end">
            <v-btn v-model="showRelevancySettings" @click="showRelevancySettings = !showRelevancySettings" text :color="(showRelevancySettings) ? 'primary' : 'grey lighten-1'">
              <v-icon v-if="showRelevancySettings" left>mdi-eye</v-icon>
              <v-icon v-else left>mdi-eye-off</v-icon>
              {{ 'toggleRelevancySettings'|localize }}
            </v-btn>
          </v-col>
        </v-row>
        <template v-if="showRelevancySettings">
          <v-row class="my-0 mb-2">
            <v-col cols="2" class="py-0">
              <v-select
                :background-color="transport_mode ? 'serenade' : ''"
                v-model="transport_mode"
                :items="transportModeList"
                item-text="value"
                item-value="key"
                dense
                hide-details
                clearable
                outlined
                :disabled="saveLoading"
                :placeholder="'transportMode'|localize"
              >
                <template v-slot:item="{ item, on }">
                  <v-icon class="mr-2" color="silver-chalice">{{ TRANSPORT_ICONS[item['value']] }}</v-icon>
                  <v-list-item-title>{{ item.value|localize }}</v-list-item-title>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-icon
                    v-if="index <= 1"
                    class="mr-1"
                    color="silver-chalice"
                  >{{ TRANSPORT_ICONS[item['value']] }}
                  </v-icon>
                  <span v-if="index === 2" class="grey--text text-caption">+{{ transport_mode.length - 2 }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="2" class="py-0">
              <v-select
                :background-color="type_of_goods.length ? 'serenade' : ''"
                v-model="type_of_goods"
                :items="goodTypesList"
                item-text="value"
                item-value="key"
                dense
                hide-details
                clearable
                outlined
                multiple
                :disabled="saveLoading"
                :placeholder="'typeOfGoods'|localize"
              >
                <template v-slot:item="{ item, on }">
                  <v-list-item-title>{{ item.value }}</v-list-item-title>
                </template>
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0" class="text-truncate" style="max-width: 50px">{{ item.value }}</span>
                  <span v-if="index === 1" class="grey--text text-caption ml-1">+{{ type_of_goods.length - 1 }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="3" class="py-0">
              <v-select
                :background-color="special_requirements.length ? 'serenade' : ''"
                v-model="special_requirements"
                :items="requirementsList"
                :item-text="item => `${item.value}`"
                :item-value="item => `${item.key}`"
                dense
                hide-details
                clearable
                outlined
                multiple
                :disabled="saveLoading"
                :placeholder="'specialRequirements'|localize"
              >
                <template v-slot:item="{ item, on }">
                  <v-icon class="mr-2" :color="SPECIAL_REQUIREMENTS_COLORS[item['key']]">{{ SPECIAL_REQUIREMENTS_ICONS[item['key']] }}</v-icon>
                  <v-list-item-title>{{ item['key']|localize }}</v-list-item-title>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-icon class="mr-2" :color="SPECIAL_REQUIREMENTS_COLORS[item['key']]">{{ SPECIAL_REQUIREMENTS_ICONS[item['key']] }}</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col cols="2" class="py-0">
              <v-select
                :background-color="receiver.length ? 'serenade' : ''"
                v-model="receiver"
                :items="receiverList"
                item-text="value"
                item-value="key"
                dense
                hide-details
                clearable
                outlined
                multiple
                :disabled="saveLoading"
                :placeholder="'receiver'|localize"
              >
                <template v-slot:item="{ item, on }">
                  <v-list-item-title>{{ item.value }}</v-list-item-title>
                </template>
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0" class="text-truncate" style="max-width: 150px">{{ item.value }}</span>
                  <span v-if="index === 1" class="grey--text text-caption mx-1">+{{ receiver.length - 1 }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="3" class="py-0">
              <v-select
                :background-color="delivery_terms.length ? 'serenade' : ''"
                v-model="delivery_terms"
                :items="deliveryTerms"
                item-text="text"
                item-value="value"
                dense
                hide-details
                clearable
                outlined
                multiple
                :disabled="saveLoading"
                :placeholder="'deliveryTerms'|localize"
              >
                <template v-slot:item="{ item, on }">
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </template>
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0" class="text-truncate mr-1" style="max-width: 100px">{{ item.value }}</span>
                  <span v-if="index === 1" class="grey--text text-caption mr-1">+{{ delivery_terms.length - 1 }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row class="my-0 mb-2">
            <v-col cols="3" class="py-0">
              <v-select
                :background-color="region_from.length ? 'serenade' : ''"
                v-model="region_from"
                :items="REGIONS"
                :item-text="item => `${item.value}`"
                :item-value="item => item.key"
                dense
                hide-details
                clearable
                outlined
                multiple
                :disabled="saveLoading"
                @change="regionFromHandler"
                :placeholder="'regionFrom'|localize"
              >
                <template v-slot:item="{ item, on }">
                  <v-list-item-title class="body-2">{{ item.value }}</v-list-item-title>
                </template>
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0" class="text-truncate mr-1" style="max-width: 100px">{{ item.value }}</span>
                  <span v-if="index === 1" class="grey--text text-caption mr-1">+{{ region_from.length - 1 }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="3" class="py-0">
              <v-autocomplete
                :background-color="country_from.length ? 'serenade' : ''"
                v-model="country_from"
                :items="countryFromList"
                :item-text="item => `${item.name} ${item.code}`"
                :item-value="item => `${item.code}`"
                dense
                hide-details
                clearable
                outlined
                multiple
                :disabled="saveLoading"
                :placeholder="'countryFrom'|localize"
              >
                <template v-slot:item="{ item, on }">
                  <v-list-item-title class="body-2">{{ item.name }} ({{item.code}})</v-list-item-title>
                </template>
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0" class="text-truncate mr-1" style="max-width: 100px">{{ item.name }}</span>
                  <span v-if="index === 1" class="grey--text text-caption mr-1">+{{ country_from.length - 1 }}</span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="3" class="py-0">
              <v-select
                :background-color="region_to.length ? 'serenade' : ''"
                v-model="region_to"
                :items="REGIONS"
                :item-text="item => `${item.value}`"
                :item-value="item => item.key"
                dense
                hide-details
                clearable
                outlined
                multiple
                :disabled="saveLoading"
                @change="regionToHandler"
                :placeholder="'regionTo'|localize"
              >
                <template v-slot:item="{ item, on }">
                  <v-list-item-title class="body-2">{{ item.value }}</v-list-item-title>
                </template>
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0" class="text-truncate mr-1" style="max-width: 100px">{{ item.value }}</span>
                  <span v-if="index === 1" class="grey--text text-caption mr-1">+{{ region_to.length - 1 }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="3" class="py-0">
              <v-autocomplete
                :background-color="country_to.length ? 'serenade' : ''"
                v-model="country_to"
                :items="countryToList"
                :item-text="item => `${item.name} ${item.code}`"
                :item-value="item => `${item.code}`"
                dense
                hide-details
                clearable
                outlined
                multiple
                :disabled="saveLoading"
                :placeholder="'countryTo'|localize"
              >
                <template v-slot:item="{ item, on }">
                  <v-list-item-title class="body-2">{{ item.name }} ({{item.code}})</v-list-item-title>
                </template>
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0" class="text-truncate mr-1" style="max-width: 100px">{{ item.name }}</span>
                  <span v-if="index === 1" class="grey--text text-caption mr-1">+{{ country_to.length - 1 }}</span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="justify-end">
            <v-col cols="4" class="d-flex align-сenter justify-end">
              <span v-if="selected.length > 0" class="mr-8 pt-2 caption">
                {{ 'usersSelected'|localize }}: {{selected.length}}
              </span>
              <v-btn
                rounded
                depressed
                color="primary"
                :loading="saveLoading"
                :disabled="saveLoading || selected.length === 0"
                @click="$refs.saveDialog.open()"
              >
                {{ 'massiveUpdate'|localize }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </div>
      <SimpleDialog
        ref="saveDialog"
        :title="'saveRelevancyTitle'|localize"
        :text="'saveRelevancyText'|localize"
        :accept-text="'save'|localize"
        :decline-text="'back'|localize"
        @accept="saveRelevancy"
      />
  </v-container>
</template>

<script>
  import localize from '@/filters/localize';
  import methods from '@/helpers/methods';
  import api from '@/api/api';
  import {TRANSPORT_ICONS} from '@/enums/transportMode';
  import {REGIONS} from '@/enums/regions';
  import {COUNTRY_LIST} from '@/enums/countryList';
  import SimpleDialog from '@/components/common/SimpleDialog';
  import { ROLES } from '@/enums/roles';
  import { SPECIAL_REQUIREMENTS_COLORS, SPECIAL_REQUIREMENTS_ICONS } from '@/enums/specialRequirements';

  export default {
    name: 'UsersRelevancy',
    components: {SimpleDialog},

    data() {
      return {
        TRANSPORT_ICONS,
        COUNTRY_LIST,
        REGIONS,
        loading: true,
        filtersLoading: false,
        defaultSortBy: '',
        defaultSortDesc: true,
        debouncedSearch: '',
        debounceTimeout: null,
        options: {},
        companies: [],
        countriesFrom: [],
        countriesTo: [],
        filters: {
          country_from: [],
          country_to: [],
          transport_mode: [],
          type_of_goods: [],
          company: [],
          receiver: [],
          special_requirements: [],
          relevanciesSet: '',
          delivery_terms: []
        },
        itemsPerPage: 20,
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        relevancyList: [],
        selected: [],
        transport_mode: null,
        type_of_goods: [],
        region_from: [],
        country_from: [],
        region_to: [],
        country_to: [],
        special_requirements: [],
        receiver: [],
        delivery_terms: [],
        saveLoading: false,
        showFilters: true,
        showRelevancySettings: true,
        loginLocalId: null,
        clients: []
      };
    },

    async mounted() {
      try {
        this.filtersLoading = true;
        let filtersData = await api.getRelevancyFilters();
        this.companies = filtersData.data['company'];
        this.countriesFrom = filtersData.data['country_from'];
        this.countriesTo = filtersData.data['country_from'];
        this.filtersLoading = false;
      } catch (e) {
        this.filtersLoading = false;
      }
    },

    watch: {
      options: {
        handler() {
          this.clearRelevancy();
          this.getRelevancy();
        },
        deep: true,
      },
      search() {
        this.clearRelevancy();
        this.getRelevancy();
      },
      itemsPerPage() {
        this.getRelevancy();
      }
    },

    computed: {
      SPECIAL_REQUIREMENTS_ICONS() {
        return SPECIAL_REQUIREMENTS_ICONS
      },
      SPECIAL_REQUIREMENTS_COLORS() {
        return SPECIAL_REQUIREMENTS_COLORS
      },
      headers() {
        return [
          {text: localize('users'), value: 'user_name', sortable: true, width: 180},
          {text: localize('transportMode'), value: 'transport_mode', sortable: false},
          {text: localize('typeOfGoods'), value: 'type_of_goods', sortable: false, width: 115},
          {text: localize('deliveryTerms'), value: 'delivery_terms', sortable: false, width: 130},
          {text: localize('countryFrom'), value: 'country_from', sortable: false, width: 170},
          {text: localize('countryTo'), value: 'country_to', sortable: false, width: 170},
          {text: localize('specialRequirements'), value: 'special_requirements', sortable: false},
          {text: localize('receiver'), value: 'receiver', sortable: false},
          {text: '', value: 'is_valid', sortable: false, width: 50},
          {text: '', value: 'settings', sortable: false, width: 50},
        ];
      },
      deliveryTerms() {
        return this.$store.getters.deliveryTerms;
      },
      goodTypesList() {
        return this.$store.getters.goodTypesSorted.all;
      },
      transportModeList() {
        return this.$store.getters.transportModeList;
      },
      requirementsList() {
        return this.$store.getters.requirementsList;
      },
      receiverList() {
        return this.$store.getters.receiverList;
      },
      relevanciesSetList() {
        return this.$store.getters.relevanciesSetList;
      },
      countryFromList() {
        let countries = [];
        this.region_from.forEach(element => {
          countries = countries.concat(this.COUNTRY_LIST.filter(item => item.region_code === element))
        });
        return (countries.length) ? countries : [...COUNTRY_LIST];
      },
      countryToList() {
        let countries = [];
        this.region_to.forEach(element => {
          countries = countries.concat(this.COUNTRY_LIST.filter(item => item.region_code === element))
        });
        return (countries.length) ? countries : [...COUNTRY_LIST];
      },
      itemsPerPageList() {
        return [10,20,30,50];
      },
      search: {
        get() {
          return this.debouncedSearch
        },
        set(val) {
          if (this.debounceTimeout) clearTimeout(this.debounceTimeout)
          this.debounceTimeout = setTimeout(() => {
            this.debouncedSearch = val
          }, 500)
        }
      },
      computedRelevancyList() {
        let localRelevancy = JSON.parse(JSON.stringify(this.relevancyList));
        localRelevancy.forEach((row) => {
          if (row['blocked_clients_custno']?.length) {
            row['black_list'] = this.clients.filter(client => row['blocked_clients_custno'].includes(client['user_id']));
          }
        });
        return localRelevancy;
      }
    },

    methods: {
      resetFilters() {
        this.filters.company = [];
        this.filters.transport_mode = [];
        this.filters.type_of_goods = [];
        this.filters.country_from = [];
        this.filters.country_to = [];
        this.filters.special_requirements = [];
        this.filters.receiver = [];
        this.filters.delivery_terms = [];
      },
      resetSettings() {
        this.transport_mode = null;
        this.type_of_goods = [];
        this.special_requirements = [];
        this.receiver = [];
        this.delivery_terms = [];
        this.region_from = [];
        this.country_from = [];
        this.region_to = [];
        this.country_to = [];
      },
      regionFromHandler() {
        this.country_from = (this.region_from.length) ? [...this.countryFromList.map(item => item.code)] : [];
      },
      regionToHandler() {
        this.country_to = (this.region_to.length) ? [...this.countryToList.map(item => item.code)] : [];
      },
      clearRelevancy() {
        this.relevancyList = [];
        this.totalPages = 0;
        this.totalItems = 0;
        this.currentPage = 1;
      },
      queryData() {
        let queryData = {};
        queryData.page = this.currentPage;
        if (this.search) queryData.search = this.search;
        this.options.sortBy[0] && (
          queryData['sort'] = {
            field: this.options.sortBy[0],
            desc: this.options.sortDesc[0],
          });
        queryData.per_page = this.itemsPerPage;
        queryData.filters = {};
        this.filters.company.length && (queryData.filters.company = this.filters.company);
        this.filters.transport_mode.length && (queryData.filters.transport_mode = this.filters.transport_mode);
        this.filters.type_of_goods.length && (queryData.filters.type_of_goods = this.filters.type_of_goods);
        this.filters.country_from.length && (queryData.filters.country_from = this.filters.country_from);
        this.filters.country_to.length && (queryData.filters.country_to = this.filters.country_to);
        this.filters.special_requirements.length && (queryData.filters.special_requirements = this.filters.special_requirements);
        this.filters.receiver.length && (queryData.filters.receiver = this.filters.receiver);
        this.filters.delivery_terms.length && (queryData.filters.delivery_terms = this.filters.delivery_terms);
        if (this.filters.relevanciesSet) {
          if (this.filters.relevanciesSet === 'yes') {
            queryData.filters.is_valid = true;
          }
          if (this.filters.relevanciesSet === 'no') {
            queryData.filters.is_valid = false;
          }
        }
        return queryData;
      },
      async getRelevancy() {
        try {
          this.loading = true;
          let relevancyData = await api.getUsersRelevancyList(this.queryData());
          if (!this.clients.length) {
            let clientsData = await api.getClients({ paginate: 0 });
            this.clients = clientsData.data.data;
          }
          this.setRelevancy(relevancyData);
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      },
      setRelevancy(relevancyData) {
        let localRelevancy = relevancyData.data.data;
        localRelevancy.forEach(item => {
          item['local_id'] = `${item['user_id']}-${item['transport_mode']}-${item['relevancy_id']}-${item['company_id']}`;
        });
        this.relevancyList = localRelevancy;
        this.totalPages = relevancyData.data.pagination['total_page'];
        this.totalItems = relevancyData.data.pagination['total_items'];
      },
      async saveRelevancy() {
        if (this.selected.length > 0) {
          this.saveLoading = true;
          try {
            await api.saveUsersRelevancyMultiple(this.relevancyOptions());
            let relevancyData = await api.getUsersRelevancyList(this.queryData());
            this.setRelevancy(relevancyData);
            this.saveLoading = false;
            this.selected = [];
            this.resetSettings();
            methods.showGlobalSnackbar('success', localize('done'));
          } catch (e) {
            this.saveLoading = false;
            methods.showGlobalSnackbar('error', methods.getErrors(e.response));
          }
        }
      },
      relevancyOptions() {
        let relevancyOptions = {};
        relevancyOptions.users_relevancy = JSON.stringify(this.selected.map(item => {
          return { user_id: item.user_id, relevancy_id: item.relevancy_id };
        }));
        if (this.transport_mode) relevancyOptions.transport_mode = this.transport_mode;
        if (this.region_from.length > 0) {
          relevancyOptions.region_from = JSON.stringify(this.region_from.map(regionCode => {
            return { key: regionCode, value: REGIONS.filter(item => item.key === regionCode)[0].value };
          }));
        }
        if (this.country_from.length > 0) {
          relevancyOptions.country_from = JSON.stringify(this.country_from.map(countryCode => {
            return { code: countryCode, name: COUNTRY_LIST.filter(item => item.code === countryCode)[0].name };
          }));
        }
        if (this.region_to.length > 0) {
          relevancyOptions.region_to = JSON.stringify(this.region_to.map(regionCode => {
            return { key: regionCode, value: REGIONS.filter(item => item.key === regionCode)[0].value };
          }));
        }
        if (this.country_to.length > 0) {
          relevancyOptions.country_to = JSON.stringify(this.country_to.map(countryCode => {
            return { code: countryCode, name: COUNTRY_LIST.filter(item => item.code === countryCode)[0].name };
          }));
        }
        if (this.type_of_goods.length > 0) relevancyOptions.type_of_goods = JSON.stringify(this.type_of_goods);
        if (this.special_requirements.length > 0) relevancyOptions.special_requirements = JSON.stringify(this.special_requirements);
        if (this.receiver.length > 0) relevancyOptions.receiver = JSON.stringify(this.receiver);
        if (this.delivery_terms.length > 0) relevancyOptions.delivery_terms = JSON.stringify(this.delivery_terms);
        return relevancyOptions;
      },
      async loginAsUser(user) {
        try {
          this.loginLocalId = user['local_id'];
          let userData = await api.loginAsUser({email: user['user_email'], locale: 'se'});
          let localUserData = userData['data'];
          await api.logout();
          methods.clearUserData();
          if (localUserData.role === ROLES.COMPANY) {
            if (localUserData.companies.length === 1) {
              localUserData.company = localUserData.companies[0];
            } else {
              localUserData.company = localUserData.companies.filter(company => company['id'].toString() === user.company_id.toString())?.[0];
            }
          }
          methods.saveUserData(localUserData);
          methods.showGlobalSnackbar('success', `${localize('hello')} ${localUserData['name']}`);
          methods.afterLogin(localUserData['locale']);
        } catch (e) {
          this.loginLocalId = null;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .name-col {
    display: block;
    max-width: 170px;
    overflow-wrap: break-word;
  }
</style>
